import {mapActions, mapGetters} from "vuex";


export default {
  name: "bonus",

  data(){
    return{

    }
  },
  created() {
    this.getBonus()
  },
  computed:{
    ...mapGetters({
      bonus: 'profile/bonus'
    }),

  },
  methods:{
    ...mapActions({
      getBonus: 'profile/FETCH_BONUS'
    }),
  }
}